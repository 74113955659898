



















































import Vue from 'vue'
//import ItemFilters from '@/components/ItemFilters.vue'
import StockItems from '@/views/stocks/StockItems.vue'
import EditItemsForm from '@/components/EditRequisitionItemsForm.vue'
import GenerateDocumentModal from '@/components/GenerateDocumentModal.vue'
import { ApiResponse, Model, RequisitionItem } from '@/interfaces'
import { mapState } from 'vuex'

interface DropdownAction {
  action: string
}

export default Vue.extend({
  name: 'BoughtItems',
  components: { StockItems, EditItemsForm, GenerateDocumentModal },
  props: {
    contact: {
      type: String,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      itemsChecked: 0,
      selectedItems: [] as number[],
      // showGenerateDocumentModal: false,
      showMassEditItemsModal: false,
      models: [] as Model[],
      structures: [],
      chosenModel: null as number | null,
      chosenStructure: null as number | null,
      customText: null as string | null,
      refreshTrigger: false,
      toggleDocumentModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      // if (newVal) {
      //   this.structures = newVal.client.structures
      // }
    },
    activeTab(newVal) {
      // if (newVal && newVal === 'items') {
      //   this.getTemplates()
      // }
    },
  },
  mounted() {
    // if (this.activeTab === 'items' && this.models.length === 0) {
    //   this.getTemplates()
    // }

    // if (this.user) {
    //   this.structures = this.user.client.structures
    // }
  },
  methods: {
    // getTemplates() {
    //   this.$api.get(`/model/contact/list`).then((response) => {
    //     const apiResponse = response.data as ApiResponse

    //     this.models = apiResponse.data
    //   })
    // },
    handleStockItemCommand(command: DropdownAction) {
      // Generate document
      // if (command.action == 'document' && this.itemsChecked > 0) {
      //   this.openGenerateDocumentModal()
      // }
      // Generate document
      if (command.action == 'generateDocument' && this.itemsChecked > 0) {
        this.toggleDocumentModal = !this.toggleDocumentModal
      }
      // Edit items
      if (command.action == 'massEdit' && this.itemsChecked > 0) {
        this.openMassEditModal()
      }
    },
    // openGenerateDocumentModal() {
    //   this.showGenerateDocumentModal = true
    // },
    // closeGenerateDocumentModal() {
    //   this.chosenModel = null
    //   this.chosenStructure = null
    //   this.customText = null
    //   this.showGenerateDocumentModal = false
    // },
    // generateBoughtItemsDocument() {
    //   if (!this.busy) {
    //     this.busy = true
    //     let success = false
    //     this.$api
    //       .post(`/document/${this.contact}/contact`, {
    //         chosenModel: this.chosenModel,
    //         chosenStructure: this.chosenStructure,
    //         ids: this.selectedItems,
    //         customText: this.customText,
    //       })
    //       .then((response) => {
    //         const apiResponse = response.data as ApiResponse
    //         this.$emit('updateDocuments', apiResponse.data.documents)

    //         const size = apiResponse.data.size
    //         const time = apiResponse.data.time

    //         this.$notify({
    //           type: 'success',
    //           title: 'Succès',
    //           message: `Le document a été créé avec succès en ${time} secondes et ${size} Mo ! Il est disponible en téléchargement dans l'onglet Documents. L'état du stock et la date de sorti du lot ont été mis à jour.`,
    //         })
    //         success = true

    //         this.refreshTrigger = !this.refreshTrigger
    //         this.uncheckAll()
    //       })
    //       .catch((error) => {
    //         if (error.response) {
    //           const apiResponse = error.response.data as ApiResponse
    //           this.$notify({
    //             type: 'error',
    //             title: 'Erreur',
    //             message:
    //               apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
    //           })
    //         }
    //       })
    //       .finally(() => {
    //         this.busy = false
    //         if (success) {
    //           this.closeGenerateDocumentModal()
    //         }
    //       })
    //   }
    // },
    updateStockItems(items: RequisitionItem[]) {
      ;(this.$refs.stockItems as InstanceType<typeof StockItems>).updateItems(items)
    },
    uncheckAll() {
      ;(this.$refs.stockItems as InstanceType<typeof StockItems>).toggleCheckAll(false)
    },
    openMassEditModal() {
      this.showMassEditItemsModal = true
    },
    closeMassEditModal() {
      this.showMassEditItemsModal = false
      this.itemsChecked = 0
      this.selectedItems = []
    },
    setItemsChecked(itemsChecked: number) {
      this.itemsChecked = itemsChecked
    },
    setSelectedItems(itemsToEdit: number[]) {
      this.selectedItems = itemsToEdit
    },
    setItemsTotal(itemsTotal: number) {
      this.$emit('setItemsTotal', itemsTotal)
    },
  },
})
